import "./src/styles/global.scss"
import "@fontsource/montserrat"

import ReactDOM from "react-dom"

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}
