exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-business-associate-agreement-js": () => import("./../../../src/pages/business-associate-agreement.js" /* webpackChunkName: "component---src-pages-business-associate-agreement-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-doctor-registration-js": () => import("./../../../src/pages/doctor-registration.js" /* webpackChunkName: "component---src-pages-doctor-registration-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invalid-js": () => import("./../../../src/pages/invalid.js" /* webpackChunkName: "component---src-pages-invalid-js" */),
  "component---src-pages-order-tracking-js": () => import("./../../../src/pages/order-tracking.js" /* webpackChunkName: "component---src-pages-order-tracking-js" */),
  "component---src-pages-plan-and-pricing-js": () => import("./../../../src/pages/plan-and-pricing.js" /* webpackChunkName: "component---src-pages-plan-and-pricing-js" */),
  "component---src-pages-plan-and-pricing-policy-js": () => import("./../../../src/pages/plan-and-pricing-policy.js" /* webpackChunkName: "component---src-pages-plan-and-pricing-policy-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-private-labelling-solution-js": () => import("./../../../src/pages/private-labelling-solution.js" /* webpackChunkName: "component---src-pages-private-labelling-solution-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-support-center-billing-and-invoicing-js": () => import("./../../../src/pages/support-center/billing-and-invoicing.js" /* webpackChunkName: "component---src-pages-support-center-billing-and-invoicing-js" */),
  "component---src-pages-support-center-index-js": () => import("./../../../src/pages/support-center/index.js" /* webpackChunkName: "component---src-pages-support-center-index-js" */),
  "component---src-pages-support-center-registering-on-alignerbase-js": () => import("./../../../src/pages/support-center/registering-on-alignerbase.js" /* webpackChunkName: "component---src-pages-support-center-registering-on-alignerbase-js" */),
  "component---src-pages-support-center-signing-into-your-dashboard-js": () => import("./../../../src/pages/support-center/signing-into-your-dashboard.js" /* webpackChunkName: "component---src-pages-support-center-signing-into-your-dashboard-js" */),
  "component---src-pages-support-center-the-main-dashboard-js": () => import("./../../../src/pages/support-center/the-main-dashboard.js" /* webpackChunkName: "component---src-pages-support-center-the-main-dashboard-js" */),
  "component---src-pages-support-center-the-orders-dashboard-js": () => import("./../../../src/pages/support-center/the-orders-dashboard.js" /* webpackChunkName: "component---src-pages-support-center-the-orders-dashboard-js" */),
  "component---src-pages-support-center-the-patients-dashboard-js": () => import("./../../../src/pages/support-center/the-patients-dashboard.js" /* webpackChunkName: "component---src-pages-support-center-the-patients-dashboard-js" */),
  "component---src-pages-support-center-the-profile-dashboard-js": () => import("./../../../src/pages/support-center/the-profile-dashboard.js" /* webpackChunkName: "component---src-pages-support-center-the-profile-dashboard-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-track-js": () => import("./../../../src/pages/track.js" /* webpackChunkName: "component---src-pages-track-js" */),
  "component---src-pages-unsubscribed-js": () => import("./../../../src/pages/unsubscribed.js" /* webpackChunkName: "component---src-pages-unsubscribed-js" */),
  "component---src-pages-verified-js": () => import("./../../../src/pages/verified.js" /* webpackChunkName: "component---src-pages-verified-js" */)
}

